import { Component, OnInit, HostListener, ViewChild, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { UntypedFormControl, UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import {
  InventoryStateClient, InventoryStateModel, OrderItemCommentModel,
  OrderItemDestinationModel, OrderStatusModel, OrderStatusClient
  ,ProcurementClient, SearchClient, SearchCriteriaModel,
  SearchFormulaModel, UnitOfMeasureModel,
  UnitOfMeasureClient,
  OrderItemModel,
  OrderItemForecastDestinationModel,
  UserModel,
  MaterialPlannerRequestModel,
  UserClient,
  PlannerItem,
  MaterialPlanningClient
} from '../services/forecast-api-client.service';
import { SecurityService } from '../services/security.service';
import { forkJoin, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { AppComponent } from '../app.component';
import { DataViewModule, DataView } from 'primeng/dataview';
import { Dropdown } from 'primeng/dropdown';

const orderProps = ['estimatedOrderQuantity', 'estimatedOrderQuantityUom',
  'additionalQuantity', 'orderStatus', 'supplierPlantCode', 'quantityOrdered', 'orderQuantityUom', 'orderSubmissionDate', 'orderConfirmationNumber'];
// interface iOrderItemModel extends OrderItemModel{
//   dirty?: boolean;
// }
@Component({
  selector: 'app-procurement',
  templateUrl: './procurement.component.html',
  styleUrls: ['./procurement.component.scss']
})
export class ProcurementComponent implements OnInit {
  @ViewChild('dv1') dataView: DataView;
  pageIndex: number = 1;
  pageItemNumber: number = 25;
  totalRecords: number;
  totalRecordsCount: number = 0;
  isSortOrderDesc :boolean = false;
  showOrderItems: boolean = false;
  wipOrderStatusDropdown: Array<OrderStatusModel>;
  orderStatus: Array<OrderStatusModel>;
  orderStatusDropdown: Array<OrderStatusModel>;
  indexes = [];
  isAccordionOpen: boolean = false;
  filterForm: UntypedFormGroup;
  orderItems: any = [];
  searchFilters: Array<SearchFormulaModel>;
  filteredMaterialPlanners:any[];
  filteredUsers:any[];
  userInfo: UserModel;
  selectedFilter: SearchFormulaModel = new SearchFormulaModel();
  openAccordion: boolean = true;
  defaultFilter: SearchFormulaModel;
  searchCriteriaList: SearchCriteriaModel[];
  quantityUOMs: Array<UnitOfMeasureModel>;
  saveOrderItems: Array<OrderItemModel> = [];
  isFormChanged: boolean;
  orderItemsCopy: OrderItemModel[];
  isAdditionalQuantityInvalid: boolean = false;
  isQuantityNeededInvalid: boolean = false;
  isOrderQuantityInvalid: boolean = false;
  searchFilterForm = {};
  disabledSave: boolean = false;
  disabledFilterSave: boolean = false;
  DefaultFilterId: number = 2;
  WIPAssignedToMeFilterId: number = 3;
  orderBy:any[] = [{name: 'Select', code: 0},{name: 'Date Range', code: 1} , {name: 'Product Name', code: 2}
,{name: 'Product Type', code: 3}, {name: 'Status', code: 4}, {name: 'Earliest Date Needed', code: 4}];  private assignPlanner: OrderItemModel = new OrderItemModel;
  filter: any = {
    PlantCode: null,
    Product: null,
    MaterialPlannerUserId: null,
    DateNeeded: null,
    ForecasterUserId: null,
    ForecastNumber: null,
    Keyword: null,
    Name: null,
    Status: null,
    UserName: null,
    OrderBy: null,
    Id: 0
  };

  @ViewChild('ddown') ddown : Dropdown;
  defaultOrderBy = ["New", "Follow-up", "Ordered", "Received", "In Inventory", "Partial Receipt", "Cancelled", "Outsourced"];
  materialPlanners: any;
  MaterialsAssignedToMe: SearchFormulaModel;
  constructor(private fb: UntypedFormBuilder,
    private materialPlanningClient: MaterialPlanningClient,
    private procurementClient: ProcurementClient,
    private activatedRoute: ActivatedRoute,
    private orderStatusClient: OrderStatusClient,
    private searchClient: SearchClient,
    private securityService: SecurityService,
    private unitOfMeasureClient: UnitOfMeasureClient,
    private appComponent: AppComponent,
    private router: Router,
    private messageService: MessageService,
    private userClient: UserClient,
    private commonService: CommonService,
    private changeDetection: ChangeDetectorRef

  ) {
  }

  StopDefaults() {
    this.openAccordion = false;
  }
  addChangeDetection(action: EventEmitter<Object>){
    action.subscribe(() => {
      this.changeDetection.detectChanges(); 
      (setTimeout(() => {
        this.changeDetection.detectChanges();
        
      }));
    });
  }
  

  ngOnInit() {
    
    this.appComponent.currentSelection = 'procurement';
    this.userInfo = this.securityService.getUser();
    if (this.userInfo == null) {
      this.securityService.validateUser(localStorage.getItem("loggedInUsername"))
        .subscribe(data => {
          this.userInfo = data;
        });
    }
    this.Getform();
    this.getMaterialPlannerUserlist();
    this.orderStatusClient.getOrderStatus().subscribe(data => {
      data = data.sort((a, b) => {
        return this.defaultOrderBy.indexOf(a.status) - this.defaultOrderBy.indexOf(b.status)
      });
      this.wipOrderStatusDropdown = data.filter(x => x.status != 'In Inventory' && x.status != 'Cancelled' && x.status != 'Outsourced' )
      this.orderStatus = data;
      this.orderStatusDropdown = this.wipOrderStatusDropdown;
      (<UntypedFormControl>this.filterForm.controls['Status'])
      .setValue(this.wipOrderStatusDropdown, { onlySelf: true });
      this.getOrders();
    });


    this.unitOfMeasureClient.getUnitOfMeasures(false).subscribe(data => {
      this.quantityUOMs = data;
    })
    this.filterForm.valueChanges.subscribe(x => {
      let obj: any = {};
      for (const [key, value] of Object.entries(x)) {
        if (value != null && value != undefined)
          obj[key] = value
      }
      if (Object.keys(obj).length > 1) {

        this.disabledFilterSave = true

      } else {
        this.disabledFilterSave = false
      }
    });
    this.changeDetection.detectChanges();
    this.addChangeDetection(this.ddown.onBlur);
    this.addChangeDetection(this.ddown.onFocus);
    this.addChangeDetection(this.ddown.onChange);
    this.addChangeDetection(this.ddown.onHide);
    this.addChangeDetection(this.ddown.onClick);
  }
  
  additionalQuantityFilter(event: any, quantity: any) {
    this.isAdditionalQuantityInvalid = false;
    let input = quantity.toString();
    const reg = /^[0-9]{0,6}\.?[0-9]{0,3}$/;

    if (!reg.test(input)) {
      this.isAdditionalQuantityInvalid = true;
    }
  }

  quantityNeedFilter(event: any, quantity: any) {
    this.isQuantityNeededInvalid = false;
    let input = quantity.toString();
    const reg = /^[0-9]{0,6}\.?[0-9]{0,3}$/;

    if (!reg.test(input)) {
      this.isQuantityNeededInvalid = true;
    }
  }

  orderQuantityFilter(event: any, quantity: any) {
    this.isOrderQuantityInvalid = false;
    let input = quantity.toString();
    const reg = /^[0-9]{0,6}\.?[0-9]{0,3}$/;

    if (!reg.test(input)) {
      this.isOrderQuantityInvalid = true;
    }
  }
  filterMaterialPlanners(event) {
    let query = event.query;
    
        this.filteredMaterialPlanners =  [];
    for(let i = 0; i < this.materialPlanners.length ; i++) {
        let materialPlanner = this.materialPlanners[i];
        if (materialPlanner.fullName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            this.filteredMaterialPlanners.push(materialPlanner);
        }
    }
  }
  filterUsers(event)
  {
    let query = event.query;
    this.userClient.getMatchingUsers(query).subscribe(data => {
      this.filteredUsers = data;
    });
  }

  ChangeSortOrder() {
    this.isSortOrderDesc = !this.isSortOrderDesc;
    this.getOrders();
  }
  
  getOrders() {
    this.GetData();
    var ordersRequest: any = {};
    ordersRequest.pageIndex = this.pageIndex;
    ordersRequest.pageSize = this.pageItemNumber;
    ordersRequest.filterId = this.filter.filterId;
    ordersRequest.PlantCode = this.filter.PlantCode;
    ordersRequest.OrderNumber = this.filter.OrderNumber;
    ordersRequest.Product = this.filter.Product;
    ordersRequest.MaterialPlannerId = this.filter.MaterialPlannerUserId;
    ordersRequest.ForecasterId = this.filter.ForecasterUserId;
    ordersRequest.ForecastNumber = this.filter.ForecastNumber != null && this.filter.ForecastNumber.length > 0 ? parseInt(this.filter.ForecastNumber) : null;
    ordersRequest.userId = this.filter.userId;
    ordersRequest.sortOrder = this.filter.OrderBy;
    ordersRequest.isASC = !this.isSortOrderDesc;
    if(this.filter.DateNeeded != null)
    {
      if(this.filter.DateNeeded[0] != null)
      ordersRequest.fromDate = this.filter.DateNeeded[0].toDateString();
      if(this.filter.DateNeeded[1] != null)
      {
        ordersRequest.toDate = this.filter.DateNeeded[1].toDateString();
      }
    }
    if(this.filter.Name != 'All Materials')
    {
    ordersRequest.StatusIds = this.filter.statusIds;
    }
    ordersRequest.Keyword = this.filter.Keyword;
    ordersRequest.FilterName = this.filter.Name;


    let obj: any = {};
    for (const [key, value] of Object.entries(ordersRequest)) {
      if (value != null && value != undefined)
        obj[key] = value
    }
    if (this.selectedFilter == undefined || Object.keys(this.selectedFilter).length === 0) {
      obj.filterId = this.DefaultFilterId
    }

    this.procurementClient.getOrderItems(obj).subscribe(data => {
      this.showOrderItems = true;
      this.orderItemsCopy = this.deepCopyOrdersData(data.orderItems);
      console.log("orderItems", data);
      this.orderItems = data.orderItems;
      this.totalRecords = data.totalCount
      this.totalRecordsCount = data.totalCount;
      this.orderItems.forEach(item => item.orderStatusDropdown = this.orderStatus);
      if (Object.keys(this.selectedFilter).length === 0) {
        this.searchClient.getProcurementSearchFormulas(this.userInfo.id).subscribe(data => {
          this.searchFilters = data.sort(function (x, y) { return x.name == 'WIP - Materials' ? -1 : y.name == 'WIP - Materials' ? 1 : 0; });
          this.defaultFilter = this.searchFilters.filter(x => x.name == 'WIP - Materials')[0];
          this.selectedFilter = this.searchFilters.filter(x => x.name == 'WIP - Materials')[0];
          this.orderStatusDropdown = this.wipOrderStatusDropdown;
          (<UntypedFormControl>this.filterForm.controls['Status'])
            .setValue(this.wipOrderStatusDropdown, { onlySelf: true });
          (<UntypedFormControl>this.filterForm.controls['Name'])
            .setValue(this.selectedFilter.name, { onlySelf: true });
          console.log("SearchFilters,", this.searchFilters);
        });
      }

    })
  }

  loadOrderItems(event) {
    this.pageIndex = (event.first / this.pageItemNumber) + 1;
    this.getOrders()
  }

  onProcurementValueChange(index) {
    setTimeout(() => {
      this.orderItems[index].dirty = true;
      let isOrderChanged = false;
      for (let j = 0; j < this.orderItems.length; j++) {
        const order = this.orderItems[j];
        const procerument = this.orderItemsCopy.find(proc => proc.id === order.id);
        for (let i = 0; i < orderProps.length; i++) {
          const property = orderProps[i];

          if (order[property] && procerument[property]) {
            const propertyValueType = typeof order[property];
            switch (propertyValueType) {
              case ('string'):
              case ('number'):
              case ('boolean'):
                isOrderChanged = !(order[property] === procerument[property]);
                break;
              case ('object'):
                if (order[property] instanceof Date) {
                  isOrderChanged = !(order[property].valueOf() === procerument[property].valueOf());
                } else {
                  isOrderChanged = !(order[property].id === procerument[property].id);
                }
                break;
            }
          } else if ((order[property] && !procerument[property]) || (!order[property] && procerument[property])) {
            isOrderChanged = true;
          } else {
            isOrderChanged = false;
          }

          if (isOrderChanged) {
            break;
          }
        }
        if (isOrderChanged) {
          break;
        }
      }
      this.isFormChanged = isOrderChanged;
    })
  }

  deepCopyOrdersData(obj) {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    if (obj instanceof Date) {
      return new Date(obj.getTime());
    }

    if (obj instanceof Array) {
      return obj.reduce((arr, item, i) => {
        arr[i] = this.deepCopyOrdersData(item);
        return arr;
      }, []);
    }

    if (obj instanceof Object) {
      return Object.keys(obj).reduce((newObj, key) => {
        newObj[key] = this.deepCopyOrdersData(obj[key]);
        return newObj;
      }, {})
    }
  }
  collapseAll() {
    this.orderItems.forEach(x => {
      (x as Object)['isOrderTabOpen'] = false;
      (x as Object)['isProcurementTabOpen'] = false;
      (x as Object)['isForecastTabOpen'] = false;
    });
  }
  expandAll() {
    this.orderItems.forEach(x => {
      (x as Object)['isOrderTabOpen'] = true;
      (x as Object)['isProcurementTabOpen'] = true;
      (x as Object)['isForecastTabOpen'] = true;
    });
  }
  
  openProcurementDetail(id) {
    this.router.navigate(['/procurementDetail', id, false]);
  //  const url = this.router.serializeUrl(this.router.createUrlTree(['/procurementDetail', id, false]));
  //  window.open(url, '_blank');
  }
  NewOrderRequest() {
    this.router.navigate(['/neworderrequest']);
  }

  ResetForm() {
    (<UntypedFormControl>this.filterForm.controls['id'])
      .setValue(null, { onlySelf: true });
    (<UntypedFormControl>this.filterForm.controls['PlantCode'])
      .setValue(null, { onlySelf: true });
    (<UntypedFormControl>this.filterForm.controls['OrderNumber'])
      .setValue(null, { onlySelf: true });
    (<UntypedFormControl>this.filterForm.controls['Product'])
      .setValue(null, { onlySelf: true });
    (<UntypedFormControl>this.filterForm.controls['Status'])
      .setValue(null, { onlySelf: true });
    (<UntypedFormControl>this.filterForm.controls['MaterialPlannerUserId'])
      .setValue(null, { onlySelf: true });
      (<UntypedFormControl>this.filterForm.controls['DateNeeded'])
        .setValue( null , { onlySelf: true });
      (<UntypedFormControl>this.filterForm.controls['Forecaster'])
      .setValue(null, { onlySelf: true });
      (<UntypedFormControl>this.filterForm.controls['ForecastNumber'])
      .setValue(null, { onlySelf: true });
    (<UntypedFormControl>this.filterForm.controls['Keyword'])
      .setValue(null, { onlySelf: true });

    (<UntypedFormControl>this.filterForm.controls['OrderBy'])
      .setValue(null, { onlySelf: true });
    (<UntypedFormControl>this.filterForm.controls['Name'])
      .setValue(null, { onlySelf: true });
  }
  EnterToFilter(e) {
    if (e.keyCode === 13) {
      this.FilterData(false)
    }
  }
  GetData() {
    this.filter.PlantCode = (this.filterForm.get('PlantCode').value == null || this.filterForm.get('PlantCode').value.length == 0) ? null : this.filterForm.get('PlantCode').value;
    this.filter.OrderNumber = (this.filterForm.get('OrderNumber').value == null || this.filterForm.get('OrderNumber').value.length == 0) ? null : this.filterForm.get('OrderNumber').value;
    this.filter.Product = (this.filterForm.get('Product').value == null || this.filterForm.get('Product').value.length == 0) ? null : this.filterForm.get('Product').value;
    this.filter.MaterialPlannerUserId = (this.filterForm.get('MaterialPlannerUserId').value == null || this.filterForm.get('MaterialPlannerUserId').value.length == 0) ? null : this.filterForm.get('MaterialPlannerUserId').value.id;
    this.filter.DateNeeded = (this.filterForm.get('DateNeeded').value == null || this.filterForm.get('DateNeeded').value.length == 0) ? null : this.filterForm.get('DateNeeded').value;
    this.filter.ForecasterUserId = (this.filterForm.get('Forecaster').value == null || this.filterForm.get('Forecaster').value.length == 0) ? null : this.filterForm.get('Forecaster').value.id;
    this.filter.ForecastNumber = (this.filterForm.get('ForecastNumber').value == null || this.filterForm.get('ForecastNumber').value.length == 0) ? null : this.filterForm.get('ForecastNumber').value;
    this.filter.Keyword = (this.filterForm.get('Keyword').value == null || this.filterForm.get('Keyword').value.length == 0) ? null : this.filterForm.get('Keyword').value;
    this.filter.Name = (this.filterForm.get('Name').value == null || this.filterForm.get('Name').value.length == 0) ? null : this.filterForm.get('Name').value;
    this.filter.Id = (this.filterForm.get('id').value == null || this.filterForm.get('id').value == 0) ? 0 : this.filterForm.get('id').value;
    this.filter.Status = (this.filterForm.get('Status').value == null || this.filterForm.get('Status').value.length == 0) ? null : this.filterForm.get('Status').value.id;
    this.filter.statusIds = (this.filterForm.get('Status').value == null || this.filterForm.get('Status').value.length == 0) ? null : this.filterForm.get('Status').value.map(a => a.id);
    this.filter.OrderBy = (this.filterForm.get('OrderBy').value == null || this.filterForm.get('OrderBy').value.length == 0) ? null : this.filterForm.get('OrderBy').value.name;
    this.filter.filterId = null;
    if (this.selectedFilter != undefined && (this.selectedFilter.name == 'Assigned to Me' || this.selectedFilter.name == "WIP - Materials Assigned To Me")) {
      this.filter.UserName = this.userInfo.firstName + " " + this.userInfo.lastName;
      this.filter.userId = this.userInfo.id;
      this.filter.filterId = this.selectedFilter.name == "WIP - Materials Assigned To Me" ? this.WIPAssignedToMeFilterId : null;

    } else {
      this.filter.UserName = null;
      this.filter.userId = null;
    }
    if (this.selectedFilter != undefined && (this.selectedFilter.name == 'All Materials' || this.selectedFilter.name == 'WIP - Materials')) {
      if (this.selectedFilter.name == 'WIP - Materials') {
        this.filter.userId = null;
        this.filter.filterId = this.DefaultFilterId;
        this.filter.Name = this.selectedFilter.name
         this.orderStatusDropdown = this.wipOrderStatusDropdown;
        // (<FormControl>this.filterForm.controls['Status'])
        // .setValue(this.wipOrderStatusDropdown, { onlySelf: true });
      } else {
        this.filter.userId = null;
        this.filter.filterId = this.selectedFilter.id;
        this.filter.Name = this.selectedFilter.name
      }
    }
  }

  clearFilter() {
    this.Getform();
    this.selectedFilter = this.defaultFilter;    
    this.orderStatusDropdown = this.wipOrderStatusDropdown;
    (<UntypedFormControl>this.filterForm.controls['Status'])
    .setValue(this.wipOrderStatusDropdown, { onlySelf: true });
    this.pageIndex = 1;
    if(this.dataView != undefined)
      this.dataView.first = 0;
    this.getOrders();
    setTimeout(() => {
      this.Getform();
      this.orderStatusDropdown = this.wipOrderStatusDropdown;
      (<UntypedFormControl>this.filterForm.controls['Status'])
      .setValue(this.wipOrderStatusDropdown, { onlySelf: true });
        this.filterForm.get('Name').patchValue(this.selectedFilter.name);
      //this.filterForm.updateValueAndValidity();
      this.filterForm.valueChanges.subscribe(x => {
        let obj: any = {};
        for (const [key, value] of Object.entries(x)) {
          if (value != null && value != undefined)
            obj[key] = value
        }
        if (Object.keys(obj).length > 1) {
  
          this.disabledFilterSave = true
  
        } else {
          this.disabledFilterSave = false
        }
      });
    })
    this.disabledFilterSave = false;
    
  }
  AllowOnlyIntegers(e)
  {
    if(/^[0-9\s]*$/.test(e.key)) 
              {
                return true;
              }
            else
              {
                e.preventDefault();
                return false;
              }
  }

  ApplySelectedFilter($event) {

    $event.stopPropagation()
    if (this.selectedFilter.name.startsWith('WIP')) {
      this.orderStatusDropdown = this.wipOrderStatusDropdown;
      (<UntypedFormControl>this.filterForm.controls['Status'])
      .setValue(this.wipOrderStatusDropdown, { onlySelf: true });
    }
    else {
      this.orderStatusDropdown = this.orderStatus;
    }
    this.openAccordion = false;
    this.Getform();
    (<UntypedFormControl>this.filterForm.controls['Name'])
      .setValue(this.selectedFilter.name, { onlySelf: true });

    (<UntypedFormControl>this.filterForm.controls['id'])
      .setValue(this.selectedFilter.id, { onlySelf: true });

    for (var i = 0; i < this.selectedFilter.searchCriteria.length; i++) {
      switch (this.selectedFilter.searchCriteria[i].criteriaName) {

        case "PlantCode":
          (<UntypedFormControl>this.filterForm.controls['PlantCode'])
            .setValue(this.selectedFilter.searchCriteria[i].criteriaValue, { onlySelf: true });
          break;
        case "OrderNumber":
          (<UntypedFormControl>this.filterForm.controls['OrderNumber'])
            .setValue(this.selectedFilter.searchCriteria[i].criteriaValue, { onlySelf: true }); break;
        case "Product":
          (<UntypedFormControl>this.filterForm.controls['Product'])
            .setValue(this.selectedFilter.searchCriteria[i].criteriaValue, { onlySelf: true });
          break;
        case "Status":
          if (this.selectedFilter.name != 'WIP - Materials') {
            if (this.selectedFilter.searchCriteria[i].criteriaValue != null && this.selectedFilter.searchCriteria[i].criteriaValue.length > 0)
            {
              var statusIds = this.selectedFilter.searchCriteria[i].criteriaValue.split(',');
              var value = this.orderStatus.filter(s => statusIds.some(x => x === s.id.toString()));
              (<UntypedFormControl>this.filterForm.controls['Status'])
                .setValue(value, { onlySelf: true });
            }
          }
          break;
          case "MaterialPlannerUserId":
            var material = this.materialPlanners.find(r => r.id == parseInt( this.selectedFilter.searchCriteria[i].criteriaValue));
           
            (<UntypedFormControl>this.filterForm.controls['MaterialPlannerUserId'])
              .setValue(material, { onlySelf: true }); 
              break;
          case "DateNeeded":
                var dateNeeded = [];
                dateNeeded.push(null);
                dateNeeded.push(null);
                if(this.selectedFilter.searchCriteria[i].criteriaValue.indexOf('-') > 0)
                {
                  dateNeeded[0] = new Date(this.selectedFilter.searchCriteria[i].criteriaValue.split('-')[0])
                  dateNeeded[1] = new Date(this.selectedFilter.searchCriteria[i].criteriaValue.split('-')[1])
                }
                else
                {
                  dateNeeded[0] = new Date(this.selectedFilter.searchCriteria[i].criteriaValue)
                }  
              (<UntypedFormControl>this.filterForm.controls['DateNeeded'])
                  .setValue( dateNeeded, { onlySelf: true });
                break;
          case "ForecasterId":
                this.userClient.getUser(parseInt(this.selectedFilter.searchCriteria[i].criteriaValue)).subscribe(data => {
                  (<UntypedFormControl>this.filterForm.controls['Forecaster'])
                  .setValue(data, { onlySelf: true }); 
                  this.FilterData(false);
              });
              break;
          case "ForecastNumber":
            (<UntypedFormControl>this.filterForm.controls['ForecastNumber'])
              .setValue(this.selectedFilter.searchCriteria[i].criteriaValue, { onlySelf: true });
            break;
          case "Keyword":
          (<UntypedFormControl>this.filterForm.controls['Keyword'])
            .setValue(this.selectedFilter.searchCriteria[i].criteriaValue, { onlySelf: true });
          break;
        case "SortOrder":
          var sortFilter =this.selectedFilter.searchCriteria[i].criteriaValue.split('-');
             var sortOrder = this.orderBy.find(x => x.name == sortFilter[0]);
             if(sortFilter[1]== 'DESC')
             {
               this.isSortOrderDesc = true;
             }
             else{
              this.isSortOrderDesc = false;
             }
          (<UntypedFormControl>this.filterForm.controls['OrderBy'])
            .setValue(sortOrder, { onlySelf: true });
          break;
      }
    }
    this.FilterData(false);
    this.changeDetection.detectChanges();
    setTimeout(() => {
      this.changeDetection.detectChanges();
    });
  }
  FilterData(isSave: boolean) {
    this.showOrderItems = false;

    this.pageIndex = 1;
    if(this.dataView != undefined)
      this.dataView.first = 0;
    if (this.selectedFilter && this.selectedFilter.name == 'WIP - Materials') {
      var statusIds = [];
      var filteredStatus = this.selectedFilter.searchCriteria.filter(x => x.criteriaName == 'Status');
      if(filteredStatus.length > 0)
      statusIds = this.selectedFilter.searchCriteria.filter(x => x.criteriaName == 'Status')[0].criteriaValue.split(',');
    }
    this.GetData();
    var searchFormula = new SearchFormulaModel();
    var searchCriteria = new SearchCriteriaModel();
    this.searchCriteriaList = [];
    if (isSave) {
      searchFormula.name = this.filterForm.get('Name').value;
      searchFormula.id = (this.selectedFilter !== null && this.selectedFilter !== undefined && this.selectedFilter.isCannedFilter) ? 0 : this.filter.Id;
      searchFormula.isCannedFilter = false;
      searchFormula.ownerId = this.userInfo.id;
      searchFormula.enabled = true;
      searchFormula.isShared = false;
      searchFormula.dashboard = "procurement";
      searchFormula.searchCriteria = [];
    }
    console.log(this.filter, "this.filter")
    if (this.filter.Name != null) {
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "Name";
      searchCriteria.criteriaValue = this.filter.Name;
      this.searchCriteriaList.push(searchCriteria);
    }

    if (this.filter.PlantCode != null) {
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "PlantCode";
      searchCriteria.criteriaValue = this.filter.PlantCode;
      this.searchCriteriaList.push(searchCriteria);
    }
    if (this.filter.OrderNumber != null) {
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "OrderNumber";
      searchCriteria.criteriaValue = this.filter.OrderNumber;
      this.searchCriteriaList.push(searchCriteria);
    }
    if (this.filter.Product != null) {
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "Product";
      searchCriteria.criteriaValue = this.filter.Product;
      this.searchCriteriaList.push(searchCriteria);
    }
    if (this.filter.MaterialPlannerUserId != null) {
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "MaterialPlannerUserId";
      searchCriteria.criteriaValue = this.filter.MaterialPlannerUserId;
      this.searchCriteriaList.push(searchCriteria);
    }
    if (this.filter.DateNeeded != null) {
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "DateNeeded";
      if(this.filter.DateNeeded[0] != null)
      searchCriteria.criteriaValue  = this.filter.DateNeeded[0].toDateString();
      if(this.filter.DateNeeded[1] != null)
      {
        searchCriteria.criteriaValue  =searchCriteria.criteriaValue  + '-' + this.filter.DateNeeded[1].toDateString();
      }
    
      this.searchCriteriaList.push(searchCriteria);
    }
    if (this.filter.ForecasterUserId != null) {
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "ForecasterId";
      searchCriteria.criteriaValue = this.filter.ForecasterUserId;
      this.searchCriteriaList.push(searchCriteria);
    }


    if (this.filter.statusIds != null && this.filter.statusIds.length > 0) {
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "Status";
      searchCriteria.criteriaValue = this.filter.statusIds != null && this.filter.statusIds.length > 0 ? this.filter.statusIds.join(',') : null;
      this.searchCriteriaList.push(searchCriteria);
    }


    if (this.filter.UserName != null) {
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "UserName";
      searchCriteria.criteriaValue = this.filter.UserName;
      this.searchCriteriaList.push(searchCriteria);
    }

    if (this.filter.Keyword != null && this.filter.Keyword.length >= 3) {
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "Keyword";
      searchCriteria.criteriaValue = this.filter.Keyword;
      this.searchCriteriaList.push(searchCriteria);
    }

    if (this.filter.ForecastNumber != null && this.filter.ForecastNumber.length >= 1) {
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "ForecastNumber";
      searchCriteria.criteriaValue = this.filter.ForecastNumber;
      this.searchCriteriaList.push(searchCriteria);
    }

    if (this.filter.OrderBy != null) {
      let sortType = this.isSortOrderDesc ? "DESC" : "ASC";
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "SortOrder";
      searchCriteria.criteriaValue = this.filter.OrderBy.toString()+'-'+ sortType;
      this.searchCriteriaList.push(searchCriteria);
    }

    this.SortData();

    if (isSave) {
      searchFormula.searchCriteria = this.searchCriteriaList;
      if (searchFormula.id == 0) {
        this.searchClient.createSearchFormula(searchFormula).subscribe(data => {
          this.searchClient.getProcurementSearchFormulas(this.userInfo.id).subscribe(data => {
            this.searchFilters = data.sort(function (x, y) { return x.name == 'WIP - Materials' ? -1 : y.name == 'WIP - Materials' ? 1 : 0; });
            this.selectedFilter = searchFormula;

          });

        },
        error => {
          this.messageService.add({
            severity: this.commonService.configurations.MessageServiceSeverityError,
            summary: "Duplicate filter name", detail: "Please use a unique filter name."
          });
        });
      }
      else {
        searchFormula.searchCriteria.forEach(ele => ele.searchFormulaId = searchFormula.id)
        this.searchClient.updateSearchFormula(searchFormula.id, searchFormula).subscribe(data => {
          this.searchClient.getProcurementSearchFormulas(this.userInfo.id).subscribe(data => {
            this.searchFilters = data.sort(function (x, y) { return x.name == 'WIP - Materials' ? -1 : y.name == 'WIP - Materials' ? 1 : 0; });
            searchFormula = data.filter(x => x.name == searchFormula.name)[0];
            this.selectedFilter = searchFormula;

          });

        },
        error => {
          this.messageService.add({
            severity: this.commonService.configurations.MessageServiceSeverityError,
            summary: "Duplicate filter name", detail: "Please use a unique filter name"
          });
        }
        );
      }
    }
    setTimeout(() => {
      this.showOrderItems = true;
    })
    this.getOrders()
  }

  SortData() {
    var OrderByStatus = [];
    if (this.filter.OrderBy != null) {
      OrderByStatus = this.filter.OrderBy.split(',');
    }
    else {
      OrderByStatus = this.defaultOrderBy;
    }
    this.orderItems = this.orderItems.sort((a, b) => {
      return OrderByStatus.indexOf(a.orderStatus.status) - OrderByStatus.indexOf(a.orderStatus.status)
    });
  }
  Getform() {
    this.filterForm = this.fb.group({
      'id': new UntypedFormControl(0),
      'PlantCode': new UntypedFormControl(''),
      'OrderNumber': new UntypedFormControl(''),
      'MaterialPlannerUserId': new UntypedFormControl(''),
      'DateNeeded': new UntypedFormControl(''),
      'Forecaster': new UntypedFormControl(''),
      'Product': new UntypedFormControl(''),
      'Status': new UntypedFormControl(''),
      'ForecastNumber': new UntypedFormControl(''),
      'Keyword': new UntypedFormControl('', Validators.minLength(3)),
      'OrderBy': new UntypedFormControl(''),
      'Name': new UntypedFormControl('', Validators.required),
    });
    this.disabledFilterSave = false;
    this.filterForm.valueChanges.subscribe(x => {
      let obj: any = {};
      for (const [key, value] of Object.entries(x)) {
        if (value != null && value != undefined)
          obj[key] = value
      }
      if (Object.keys(obj).length > 1) {

        this.disabledFilterSave = true

      } else {
        this.disabledFilterSave = false
      }
    });
}
  myFunction(e, index) {
    //alert("I need to expand/collpase");
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const idx = this.indexes.findIndex(i => i === index);
    if (idx < 0) {
      this.isAccordionOpen = true;
      this.indexes.push(index);
    } else {
      this.isAccordionOpen = false;
      this.indexes.splice(idx, 1);
    }
    this.indexes = [...this.indexes];

  }
  discardChanges() {
    this.isFormChanged = false;
    this.getOrders();
  }

  saveOrders() {
    this.updateProcurements().subscribe(data => {
      //console.log("Successfully updated");
      this.isFormChanged = false;
      this.messageService.add({
        severity: this.commonService.configurations.MessageServiceSeveritySuccess,
        summary: 'Success', detail: "Order details are successfully updated."
      }
      );
      this.getOrders();
    },
      error => {
        this.messageService.add({
          severity: this.commonService.configurations.MessageServiceSeverityError,
          summary: 'Procurement error', detail: "Error while updating Order details."
        });
      });
  }

  private updateProcurements() {
    // console.log("Save click");
    this.saveOrderItems = [];
    var saveOrderItem = new OrderItemModel();
    this.orderItems.filter(x => x.dirty).forEach(element => {
      saveOrderItem = new OrderItemModel();
      saveOrderItem.id = element.id;
      saveOrderItem.dirty = element.dirty;
      if (element.dirty) {
        saveOrderItem.modifiedByUserId = this.userInfo.id;
      } else {
        saveOrderItem.modifiedByUserId = element.modifiedByUserId;
      }
      saveOrderItem.quantityOrdered = element.quantityOrdered;
      saveOrderItem.orderQuantityUomId = element.orderQuantityUom?.id;
      saveOrderItem.additionalQuantity = element.additionalQuantity;
      saveOrderItem.orderStatusId = element.orderStatus?.id;
      saveOrderItem.estimatedOrderQuantity = element.estimatedOrderQuantity;
      saveOrderItem.estimatedOrderQuantityUomId = element.estimatedOrderQuantityUom?.id;
      saveOrderItem.supplierPlantCode = element.supplierPlantCode;
      saveOrderItem.orderSubmissionDate = element.orderSubmissionDate;
      saveOrderItem.orderConfirmationNumber = element.orderConfirmationNumber;
      this.saveOrderItems.push(saveOrderItem);
    });
    return this.procurementClient.updateProcurements(this.saveOrderItems);
  }

  public filterstatus(itemm: OrderItemModel) //KAT285
  {

    if (itemm.orderStatus.status == "Follow-up") {
      itemm.orderStatusDropdown = itemm.orderStatusDropdown.filter(({ status }) => status !== "New");
      this.orderStatusDropdown = this.orderStatusDropdown.filter(({ status }) => status !== "New");

    }
    else if (itemm.orderStatus.status !== "New") {
      itemm.orderStatusDropdown = itemm.orderStatusDropdown.filter(({ status }) => status !== "New" && status !== "Follow-up");
      this.orderStatusDropdown = this.orderStatusDropdown.filter(({ status }) => status !== "New");
    }
  }

  setOrderStatus(item) {
    if (item.isOrderTabOpen) {
      item.isOrderTabOpen = false;
    } else {
      item.isOrderTabOpen = true
      item.isProcurementTabOpen = true;
      item.isForecastTabOpen = false;
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    if (this.canDeactivate()) {
      event.preventDefault();
      event.returnValue = true;
    }
  }

  canDeactivate(): boolean {
    return this.isFormChanged;
  }

  saveData() {
    this.updateProcurements().subscribe();
  }

  getSelectedmaterialPlanner(materialPlannerId: number) {
    if (materialPlannerId == null) {
      return null;
    }
    else {
      return this.materialPlanners.filter(x => x.id == materialPlannerId)[0];
    }
  }

  getMaterialPlannerUserlist() {
    this.userClient.getUsersInRoles(['Material Planner'])
      .subscribe(data => {
        data = data.sort((a, b) => a.fullName> b.fullName ? 1 : -1);
        this.materialPlanners = data;
      });
  }

  StopOpening(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  onCheckAddtoFP(event, orderItem:any){
    if(event.checked){
      orderItem.isManufactured = true;
      this.procurementClient.updateIsManufactured(orderItem).subscribe(response =>{
        this.messageService.add({
          severity: this.commonService.configurations.MessageServiceSeveritySuccess,
          summary: 'Success', detail: "Add to FP Updated successfully"
        });
      });
    }
  }

  setSelectedMaterialPlanner(event, orderItem: any) {
    this.assignPlanner.productName = orderItem.productName;
    this.assignPlanner.id = orderItem.id;
    this.assignPlanner.formulationTypeId = orderItem.formulationTypeId;
    let message: string = '';
    let errorTitle: string = '';
    let errorMessage: string = '';
    if (event.value) {
      message = 'Material planner assigned successfully.';
      errorTitle = 'Assign error';
      errorMessage = 'Error assigning material planner.';
      this.assignPlanner.materialPlannerUserId = event.value.id;
    } else {
      message = 'Material planner removed successfully.';
      errorTitle = 'Remove error';
      errorMessage = 'Error removing material planner.';
      this.assignPlanner.materialPlannerUserId= null;
    }
    this.assignPlanner.formulationType = orderItem.formulationType;
    console.log("Assigned Material Planner to object");
    console.log(this.assignPlanner);
    this.procurementClient.assignMaterialGroup2(this.assignPlanner).subscribe(data => {
      this.orderItems.filter(x => x.id == orderItem.id)[0].materialPlannerUserId = event.value ? event.value.id : null;
      this.messageService.add({
        severity: this.commonService.configurations.MessageServiceSeveritySuccess,
        summary: 'Success', detail: message
      });
      //this.getSelectedmaterialPlanner(data.MaterialPlannerUserId);
      // if (event.value) {
      //   plannerItem.higherStatus = plannerItem.higherStatus === 'New' ? 'To Do' : plannerItem.higherStatus;
      // } else {
      //   plannerItem.higherStatus = plannerItem.higherStatus === 'To Do' ? 'New' : plannerItem.higherStatus;
      // }
    },
      error => {
        this.messageService.add({
          severity: this.commonService.configurations.MessageServiceSeverityError,
          summary: errorTitle, detail: errorMessage
        });
      }
    );


  }


}
